import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';
import Ad from '@ifeng-fe/ui_pc_ad';
import NoSSR from 'react-no-ssr';

class RightNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { movieTitle, movieList0, shopInMovie, movieList1, movieRSAd, replaceShopConfig },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="170174" type="struct" title="影视标题" groupName="影视" content={movieTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <Chip
                    id="255868"
                    type="recommend"
                    title="影视列表1"
                    groupName="影视"
                    content={movieList0}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="short" />
                </Chip>
                {replaceShopConfig.isShow ?
                    <NoSSR>
                        <Ad content={movieRSAd} />
                    </NoSSR> :
                    <ShopList size="short" content={shopInMovie} sourceName="shopInMovie" />
                }
                <Chip
                    id="195865"
                    type="recommend"
                    title="影视列表2"
                    groupName="影视"
                    content={movieList1}
                    translate="handleNewsData">
                    <NewsList size="short" />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
