import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import Slides from '@ifeng-fe/ui_pc_slides';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';
import { isIE78 } from '../../../../utils/utils';
import { shopDataInit, shopDataHandlePlan2 } from '@src/pc/utils/shop';
import Ad from '@ifeng-fe/ui_pc_ad';

import styles from './index.css';

class LeftNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        newsSlide9: shopDataInit(this.props.content.newsSlide9),
        newsSlide8: shopDataInit(this.props.content.newsSlide8),
    }

    shopDataTransform = () => {
        const { newsSlide9, newsSlide8 } = this.props.content;

        this.setState({
            newsSlide9: shopDataHandlePlan2(newsSlide9, 'newsSlide9'),
            newsSlide8: shopDataHandlePlan2(newsSlide8, 'newsSlide8'),
        });
    }

    componentDidMount() {
        this.shopDataTransform();
    }

    shopClickHandle = (index) => {
        const { newsSlide9, newsSlide8 } = this.props.content;

        // 避免点击后数据马上发生变化 导致跳转链接异常
        setTimeout(() => {
            if (index === 7) {
                this.setState({
                    newsSlide9: shopDataHandlePlan2(newsSlide9, 'newsSlide9')
                });
            }
    
            if (index === 10) {
                this.setState({
                    newsSlide8: shopDataHandlePlan2(newsSlide8, 'newsSlide8')
                });
            }
        }, 300);
    }

    sliderTmpl = (item, isClone, index) => {
        const pcNewsMark = item?.pcNewsMark || [];
        const isAd = pcNewsMark.indexOf(5) !== -1;

        return (
            <div className={styles.big_pic} onClick={() => { this.shopClickHandle(index); }}>
                <a href={item.url} className={styles.pic_img} target="_blank" data-nomask>
                    <img src={formatImageWebpAuto(item.thumbnail, 480, 270)} className={styles.trans} />
                </a>
                <div className={styles.text} />
                <p className={styles.title}>
                    <a href={item.url} target="_blank" data-innermask>
                        {item.title}
                    </a>
                </p>
                {isAd && (
                    <img
                        src="https://x0.ifengimg.com/feprod/c/feprod/c/ifengimcp/pic/20210108/3677f2773fd79f12b079_size1_w56_h34.png"
                        className={`${styles.mark} hideMark`}
                    />
                )}
            </div>
        );
    };

    render() {
        const {
            content: {
                hotTitle,
                hotNews1,
                hotNews2,
                hotNews3,
                shopInHotNews1,
                shopInHotNews2,
                hotNewsAd,
                hotRSAd,
                replaceShopConfig
            },
        } = this.props;
        const { newsSlide9, newsSlide8 } = this.state;
        const newsSlide = [
            ...this.props.content.newsSlide0,
            ...this.props.content.newsSlide1,
            ...this.props.content.newsSlide2,
            ...this.props.content.newsSlide3,
            ...this.props.content.newsSlide4,
            ...this.props.content.newsSlide5,
            ...newsSlide9,
            ...this.props.content.newsSlide6,
            ...this.props.content.newsSlide7,
            ...newsSlide8,
        ];

        const config = {
            dots: true,
            dotsAction: 'hover',
            autoplayInterval: 8000,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,
            sliderTmpl: this.sliderTmpl,
        };

        return (
            <div className={styles.left_box}>
                <div className={`${styles.slide_box} ${isIE78() ? styles.slide : styles.slide_2x}`}>
                    <NoSSR>
                        <Slides content={newsSlide} config={config} />
                    </NoSSR>
                </div>
                <div className={styles.hot_box}>
                    <Chip id="170169" type="struct" title="热点标题" groupName="热点区" content={hotTitle}>
                        <NewsTitle size="middle" />
                    </Chip>
                    <Chip
                        id="285553"
                        type="recommend"
                        title="热点列表1"
                        groupName="热点区"
                        content={hotNews1}
                        translate="handleNewsData">
                        <NewsList hasTitle isBig size="long" ad={hotNewsAd} />
                    </Chip>
                    {replaceShopConfig.isShow ?
                        <NoSSR>
                            <Ad content={hotRSAd} />
                        </NoSSR> :
                        <Chip
                            id="260679"
                            type="struct"
                            title="热点电商1"
                            groupName="热点区"
                            content={shopInHotNews1}
                            translate="handleShopStructData">
                                <ShopList size="long" sourceName="shopInHotNews1" />
                        </Chip>
                     }
                    <Chip
                        id="285557"
                        type="recommend"
                        title="热点列表2"
                        groupName="热点区"
                        content={hotNews2}
                        translate="handleNewsData">
                        <NewsList size="long" />
                    </Chip>
                    <Chip
                        id="260681"
                        type="struct"
                        title="热点电商2"
                        groupName="热点区"
                        content={shopInHotNews2}
                        translate="handleShopStructData">
                        <ShopList size="long" sourceName="shopInHotNews2" />
                    </Chip>
                    <Chip
                        id="285558"
                        type="recommend"
                        title="热点列表3"
                        groupName="热点区"
                        content={hotNews3}
                        translate="handleNewsData">
                        <NewsList size="long" />
                    </Chip>
                </div>
            </div>
        );
    }
}

export default errorBoundary(LeftNewsBox);
