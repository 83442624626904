import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';

class CenterNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { healthTitle, healthList0, shopInEdu, healthList1, eduListAd, healthRSAd, replaceShopConfig },
        } = this.props;

        return (
            <div className={styles.center_box}>
                <Chip id="200146" type="struct" title="健康标题" groupName="健康" content={healthTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="287959"
                    type="recommend"
                    title="健康列表1"
                    groupName="健康"
                    content={healthList0}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="long" />
                </Chip>
                {replaceShopConfig.isShow ?
                    <NoSSR>
                        <Ad content={healthRSAd} />
                    </NoSSR> :
                    <ShopList size="long" content={shopInEdu} sourceName="shopInEdu" />
                }
                <Chip
                    id="287960"
                    type="recommend"
                    title="健康列表2"
                    groupName="健康"
                    content={healthList1}
                    translate="handleNewsData">
                    <NewsList size="long" ad={eduListAd} />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(CenterNewsBox);
